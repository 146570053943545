import {
    createSlice,
    createEntityAdapter,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Receiver } from "./types";
import { VenuePayloadAction } from "app/types";

type venueId = number;

const receiversAdapter = createEntityAdapter<Receiver>();

interface ReceiversState {
    receivers: Record<venueId, EntityState<Receiver, number>>;
}

const initialState: ReceiversState = {
    receivers: {},
};

export const receiversSlice = createSlice({
    name: "receivers",
    initialState,
    reducers: {
        setReceivers: (state, action: VenuePayloadAction<Receiver[]>) => {
            state.receivers[action.payload.venueId] = receiversAdapter.setAll(
                state.receivers[action.payload.venueId] ||
                    receiversAdapter.getInitialState(),
                action.payload.data
            );
        },
        resetState: (state, action: PayloadAction) => {
            return initialState;
        },
    },
});

export const { setReceivers, resetState } = receiversSlice.actions;

export const selectReceivers = (state: RootState, venueId?: number) => {
    let receivers = receiversAdapter.getInitialState();
    if (venueId && state.receivers.receivers[venueId]) {
        receivers = state.receivers.receivers[venueId];
    }
    return receiversAdapter.getSelectors().selectAll(receivers);
};

export default receiversSlice.reducer;
