import React, { ReactNode, useLayoutEffect } from "react";
import classnames from "classnames";
import styles from "./TasksOverlay.module.scss";
import { Xmark } from "icons/icons";
import iconStyles from "icons/Icon.module.css";
import Button from "./form/Button";
import Confirmation from "app/Confirmation";

interface Props {
    title: string;
    children: ReactNode;
    provingCount?: number;
    batchNumber?: number;
    setShowWarning?: (showWarning: boolean) => void;
    showWarning?: boolean;
    isValid?: boolean;
    userChanged?: boolean;
    onDone: () => void;
    onClose: () => void;
    onCompleteAll?: () => void;
    onSave?: () => void;
}

export default function TasksOverlay(props: Props) {
    let provingCount = "";
    if (props.provingCount != null) {
        provingCount = ` – ${props.provingCount} of 3 Proving Records Completed`;
    }

    let batchNumber = "";
    if (props.batchNumber) {
        batchNumber = ` – batch ${props.batchNumber}`;
    }

    const onShowWarning = () => {
        props.setShowWarning && props.setShowWarning(true);
    };

    useLayoutEffect(() => {
        // On mobile, prevent scrollable task content scrolling
        // to the bottom of the view.
        const contentContainer = document.getElementsByClassName(
            styles.contentContainer
        )[0];
        if (contentContainer) contentContainer.scrollTo(0, 0);
    }, []);

    return (
        <div className={classnames(styles.TasksOverlay, "taskScreen")}>
            {props.showWarning && (
                <div className={styles.warning}>
                    <Confirmation
                        title="Close without saving?"
                        onConfirm={() => {
                            props.setShowWarning && props.setShowWarning(false);
                            props.onClose();
                        }}
                        onAlternateConfirm={
                            props.onSave
                                ? () => {
                                      if (props.onSave) {
                                          props.setShowWarning &&
                                              props.setShowWarning(false);
                                          props.onSave();
                                      }
                                  }
                                : void 0
                        }
                        confirmTitle="Close"
                        alternateTitle="Save"
                        onCancel={() =>
                            props.setShowWarning && props.setShowWarning(false)
                        }
                    >
                        Closing this task without saving will clear what you
                        have already entered. Close anyway?
                    </Confirmation>
                </div>
            )}
            <h2 className={styles.heading}>
                {props.title}
                {provingCount}
                {batchNumber}
                {
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={
                            props.userChanged ? onShowWarning : props.onClose
                        }
                    >
                        <Xmark className={iconStyles.smallIcon} />
                    </div>
                }
            </h2>
            <div className={styles.contentContainer}>
                <div className={styles.content}>{props.children}</div>
            </div>
            <footer>
                {props.onSave && props.userChanged && (
                    <Button
                        onClick={props.onSave}
                        className={styles.save}
                        type="button"
                    >
                        Save
                    </Button>
                )}
                {props.onCompleteAll && (
                    <div
                        onClick={props.onCompleteAll}
                        className={styles.completeAll}
                    >
                        Complete All
                    </div>
                )}
                {!props.onCompleteAll &&
                    !(props.onSave && props.userChanged) && <div />}
                <Button
                    onClick={
                        !props.onSave || props.isValid ? props.onDone : void 0
                    }
                    className={
                        !props.onSave || props.isValid ? "" : styles.disabled
                    }
                    type="submit"
                >
                    Done
                </Button>
            </footer>
        </div>
    );
}
