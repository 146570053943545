import { appServiceApi } from "services/appService";
import { Receiver } from "./types";
import { setReceivers } from "./receiversSlice";
import { setServerError, selectActiveVenueId } from "app/appSlice";
import type { AppStartListening } from "app/listeners";

export const startReceiversListening = (startListening: AppStartListening) => {
    startListening({
        matcher: appServiceApi.endpoints.getReceivers.matchFulfilled,
        effect: (action, listenerApi) => {
            // Don't set data if not logged in to a venue or the
            // tasks venue is not the active venue
            const venueId = selectActiveVenueId(listenerApi.getState());
            if (!venueId || action.meta.arg.originalArgs !== venueId) return;

            const receivers = action.payload as Receiver[];
            listenerApi.dispatch(setReceivers({ venueId, data: receivers }));
            listenerApi.dispatch(setServerError(["receivers", void 0]));
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.getReceivers.matchRejected,
        effect: (action, listenerApi) => {
            if (!action.payload) return;

            const status = action.payload?.status;
            const data = action.payload?.data;
            const datetime = new Date().toISOString();
            const error = {
                httpCode: Number(status),
                data,
                datetime,
            };
            listenerApi.dispatch(setServerError(["receivers", error]));
        },
    });
};

const listeners = [startReceiversListening];
export default listeners;
