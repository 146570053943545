import { useCallback, useEffect } from "react";
import { RootState } from "./store";
import {
    selectVenueDataQueryState,
    selectUserVenuesQueryState,
} from "services/appService";
import {
    selectVenueData,
    selectHasSetData as hasSetVenueData,
} from "features/venue/venueSlice";
import {
    selectIsLoggedIn,
    selectLoginError,
    selectProfile,
} from "features/loginToken/loginTokenSlice";
import {
    selectActiveVenueId,
    selectActiveScreen,
    selectShowUserSelect,
    selectShowSignoutConfirmation,
    selectShowResetConfirmation,
    selectShowDiaryQueue,
    setHasBeenIdle,
    setShowSignoutConfirmation,
    setShowResetConfirmation,
    setShowDiaryQueue,
    selectShowInviteUser,
    resetState,
    selectServerErrors,
    selectAppToasts,
    removeAppToast,
    setError,
    setUrlPath,
    selectShowVenueSelect,
    selectShowSegmentSelect,
    ServerEndpoint,
    ServerError,
} from "./appSlice";
import { selectHasSetData as hasSetTaskData } from "features/tasks/tasksSlice";
import { selectHasSetData as hasSetSuppliersData } from "features/suppliers/suppliersSlice";
import type { screen } from "./types";
import { selectEnv } from "features/environment/envSlice";
import { VenueData } from "features/venue/types";
import { useAppSelector } from "./hooks";
import { appDispatch } from "./util";
import type { QueryState } from "services/util";
import type { AppToast } from "app/types";
import { showRoute } from "./routeListeners";
import type { LoginError } from "features/loginToken/loginTokenSlice";
import sendDebugData from "features/debug/sendDebugData";
import type { Profile } from "features/loginToken/types";

interface AppData {
    venueData?: VenueData;
    venueQueryState?: QueryState;
    venueId?: number;
    activeScreen: screen;
    loggedIn: boolean;
    loginError?: LoginError;
    serverErrors?: Record<string, string>;
    showUserSelect: boolean;
    showSignoutConfirmation: boolean;
    showResetConfirmation: boolean;
    showDiaryQueue: boolean;
    showInviteUser: boolean;
    hasSetAllData: boolean;
    toasts?: AppToast[];
    error?: string;
    showVenueSelect: boolean;
    showSegmentSelect: boolean;
    profile?: Profile;
    onIdle: () => void;
    onCancelShowSignoutConfirmation: () => void;
    onSignout: () => void;
    onCancelShowResetConfirmation: () => void;
    onReset: () => void;
    dimissToast: (toast: AppToast) => void;
    onDismissError: () => void;
    onCloseDiaryQueue: () => void;
}

function hasSetData(state: RootState, venueId?: number): boolean {
    return (
        hasSetVenueData(state, venueId) &&
        hasSetTaskData(state, venueId) &&
        hasSetSuppliersData(state, venueId)
    );
}

const NON_LOGGED_IN_SCREENS: Set<screen> = new Set([
    "invite",
    "inviteSuccess",
    "login",
    "passwordReset",
    "passwordResetInitiated",
    "setPassword",
    "setPasswordSuccess",
]);

export const useApp = (): AppData => {
    const onIdle = useCallback(() => {
        appDispatch(setHasBeenIdle(true));
    }, []);
    const env = useAppSelector(selectEnv);
    const loggedIn = useAppSelector(selectIsLoggedIn);

    const userVenueQueryState = useAppSelector(selectUserVenuesQueryState);
    const venueId = useAppSelector(selectActiveVenueId);
    const venueQueryState = useAppSelector(selectVenueDataQueryState);
    const showUserSelect = useAppSelector(selectShowUserSelect);
    const showSignoutConfirmation = useAppSelector(
        selectShowSignoutConfirmation
    );
    const showDiaryQueue = useAppSelector(selectShowDiaryQueue);
    const showInviteUser = useAppSelector(selectShowInviteUser);
    const serverErrors = useAppSelector(selectServerErrors);
    const hasSetAllData = useAppSelector((state) => hasSetData(state, venueId));
    const toasts = useAppSelector(selectAppToasts);
    const profile = useAppSelector(selectProfile);
    let debugError: ServerError | undefined;
    let loginError = useAppSelector(selectLoginError);
    if (!loginError && serverErrors && Object.keys(serverErrors).length > 0) {
        for (const endpoint of Object.keys(serverErrors)) {
            const error = serverErrors[endpoint as ServerEndpoint];
            if (error.httpCode === 401) {
                loginError = {
                    error: error,
                    errorMessage: "Unauthorised",
                    type: "app",
                };
                break;
            } else if (error.httpCode === 402) {
                loginError = {
                    error: error,
                    errorMessage: "Payment required",
                    type: "app",
                };
                break;
            } else if (error.httpCode === 403) {
                loginError = {
                    error: error,
                    errorMessage: "Forbidden",
                    type: "app",
                };
                break;
            }
        }

        const error =
            serverErrors[Object.keys(serverErrors)[0] as ServerEndpoint];
        if (!loginError && error.httpCode < 500) {
            // We shouldn't get here but just in case
            loginError = {
                error,
                errorMessage:
                    "We're experiencing a temporary glitch. Don't worry, we're onto it! Please try again in a few minutes.",
                type: "app",
            };
            debugError = error;
        }
    }
    const showVenueSelect = useAppSelector(selectShowVenueSelect);
    const showSegmentSelect = useAppSelector(selectShowSegmentSelect);

    const onSignout = useCallback(() => {
        appDispatch(resetState());
    }, []);
    const onCancelShowSignoutConfirmation = useCallback(() => {
        appDispatch(setShowSignoutConfirmation(false));
    }, []);
    const onReset = useCallback(() => {
        appDispatch(resetState());
    }, []);
    const onCancelShowResetConfirmation = useCallback(() => {
        appDispatch(setShowResetConfirmation(false));
    }, []);
    const dimissToast = useCallback((toast: AppToast) => {
        appDispatch(removeAppToast(toast));
    }, []);
    const onDismissError = useCallback(() => {
        appDispatch(setError());
    }, []);
    const onCloseDiaryQueue = useCallback(() => {
        appDispatch(setShowDiaryQueue(false));
    }, []);
    const showResetConfirmation = useAppSelector(selectShowResetConfirmation);
    let venueData = useAppSelector((state) => selectVenueData(state, venueId));
    const activeScreen = useAppSelector(selectActiveScreen);

    // Redirect from/to login page depending on login status
    useEffect(() => {
        if (loggedIn && activeScreen === "login") {
            appDispatch(setUrlPath({ url: "/", pushState: true }));
        }
        if (
            !loggedIn &&
            !NON_LOGGED_IN_SCREENS.has(activeScreen) &&
            !userVenueQueryState?.isFetching &&
            !userVenueQueryState?.isLoading
        ) {
            appDispatch(showRoute("login"));
        }

        if (debugError) {
            sendDebugData("Uncaught server error in useApp", debugError);
        }
    });

    if (!env || !venueId || !venueQueryState) {
        return {
            loggedIn,
            loginError,
            venueData,
            activeScreen: activeScreen,
            showUserSelect,
            showSignoutConfirmation,
            showResetConfirmation,
            showDiaryQueue,
            showInviteUser,
            hasSetAllData,
            toasts,
            showVenueSelect,
            showSegmentSelect,
            profile,
            onCancelShowSignoutConfirmation,
            onCancelShowResetConfirmation,
            onSignout,
            onReset,
            onIdle,
            dimissToast,
            onDismissError,
            onCloseDiaryQueue,
        };
    }

    return {
        loggedIn,
        loginError,
        venueData,
        venueQueryState,
        venueId,
        activeScreen,
        showUserSelect,
        showSignoutConfirmation,
        showResetConfirmation,
        showDiaryQueue,
        showInviteUser,
        hasSetAllData,
        toasts,
        showVenueSelect,
        showSegmentSelect,
        profile,
        onIdle,
        onCancelShowSignoutConfirmation,
        onCancelShowResetConfirmation,
        onSignout: onSignout,
        onReset,
        dimissToast,
        onDismissError,
        onCloseDiaryQueue,
    };
};
