import { useState } from "react";
import { useCombobox } from "downshift";
import { ComponentProps } from "./types";
import formStyles from "./Form.module.css";

interface SelectItem {
    label: string;
    value: number | string | boolean;
}

function getItemsFilter(inputValue: string) {
    const lowerCasedInputValue = inputValue.toLowerCase();

    return function itemsFilter(item: SelectItem) {
        return (
            !inputValue ||
            item.label.toLowerCase().includes(lowerCasedInputValue)
        );
    };
}

export default function AutoSelect(props: ComponentProps) {
    let selectItems: SelectItem[] = [];
    if (props.dataSource) {
        let dataSource = props.dataSources[props.dataSource];
        if (dataSource && Array.isArray(dataSource)) {
            selectItems = dataSource;
        }
    } else if (props.options) {
        selectItems = props.options;
    }
    const [items, setItems] = useState(selectItems);
    const {
        isOpen,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        selectItem,
    } = useCombobox({
        onInputValueChange({ inputValue }) {
            setItems(selectItems.filter(getItemsFilter(inputValue)));
            props.onChange(props.model, inputValue);
        },
        items,
        itemToString(item) {
            return item ? item.label : "";
        },
        onStateChange(changes) {
            if (
                changes.type ===
                    useCombobox.stateChangeTypes.InputKeyDownEnter &&
                items.length === 1
            ) {
                selectItem(items[0]);
            }
        },
        inputValue: props.value,
    });

    return (
        <div className="position-relative mb-3">
            <div>
                <p className={formStyles.label}>{props.label}</p>
                <input
                    type="search"
                    className={`${formStyles.input} w-100`}
                    name={props.model}
                    value={props.value}
                    placeholder={props.label}
                    {...getInputProps()}
                />
            </div>
            <ul
                className={`position-absolute w-100 bg-white mt-0 shadow-lg overflow-scroll p-0 z-3 border border-2 ${
                    !(isOpen && items.length) && "invisible"
                }`}
                {...getMenuProps()}
            >
                {isOpen &&
                    items.map((item, index) => (
                        <li
                            className={`py-1 px-2 ${formStyles.cursorPointer} ${
                                highlightedIndex === index
                                    ? formStyles.bgHighlight
                                    : ""
                            }`}
                            key={index + item.label}
                            {...getItemProps({ item, index })}
                        >
                            <span>{item.label}</span>
                        </li>
                    ))}
            </ul>
        </div>
    );
}
