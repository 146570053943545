import { ReactNode } from "react";
import useLoginButtons from "./useLoginButtons";
import Button from "features/tasks/form/Button";
import styles from "./LoginButtons.module.scss";

interface Props {
    children: ReactNode;
}

export default function LoginButtons(props: Props) {
    const { showPasswordLogin, showQrCodeLogin } = useLoginButtons();

    return (
        <div>
            {props.children && (
                <div className={styles.container}>{props.children}</div>
            )}
            <div className={styles.container}>
                {showQrCodeLogin && (
                    <Button onClick={showQrCodeLogin} className={styles.button}>
                        Sign in with QR Code
                    </Button>
                )}

                {showPasswordLogin && (
                    <Button
                        onClick={showPasswordLogin}
                        className={styles.button}
                    >
                        Login with password
                    </Button>
                )}

                {!showPasswordLogin && !showQrCodeLogin && (
                    <div>No login options</div>
                )}
            </div>

            {!props.children && (
                <>
                    <div className={styles.text}>
                        <p>
                            Don't have a QR Code? Get one{" "}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.chompfoodsafety.com/info-hub/find-your-qr-code-login"
                            >
                                here.
                            </a>
                        </p>
                        <p>
                            Not using Chomp? You really should be. Find out what
                            the fuss is all about.
                            <br />
                            Go on,{" "}
                            <a href="https://www.chompfoodsafety.com">
                                click on me
                            </a>
                            , we won't bite!
                        </p>
                    </div>
                    <div className={styles.container}></div>
                </>
            )}
        </div>
    );
}
