import React, { useCallback } from "react";
import useSidebar from "./useSidebar";
import SidebarButton from "./SidebarButton";
import DeviceId from "./DeviceId";
import NetworkStatus from "./NetworkStatus";
import { Settings, Signout, Clock, UserPlus, User } from "icons/icons";
import styles from "./Sidebar.module.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import { pluralise } from "app/util";

interface Props {
    toggleSidebar: () => void;
}

export default function Sidebar(props: Props) {
    const {
        showSettings,
        confirmSignout,
        showDiaryQueue,
        showInviteUser,
        showProfile,
        offlineStart,
        queuedDiaryEntries,
        queuedDiaryFiles,
        venueName,
        deviceId,
        buildVersion,
        profile,
        allowInviteUser,
    } = useSidebar();

    const appVersion = "v:" + buildVersion;
    const deviceIdentifier = "id:" + deviceId;

    let recordsWaitingMessages = [];
    recordsWaitingMessages.push(
        `${queuedDiaryEntries.length} ${pluralise(
            "Record",
            queuedDiaryEntries.length
        )} Waiting`
    );
    if (queuedDiaryFiles.length > 0) {
        if (queuedDiaryEntries.length === 0) recordsWaitingMessages.shift();
        recordsWaitingMessages.push(
            `${queuedDiaryFiles.length} ${pluralise(
                "File",
                queuedDiaryFiles.length
            )} Waiting`
        );
    }
    const recordsWaitingMessage = recordsWaitingMessages.join(", ");

    return (
        <>
            <Offcanvas.Header closeButton onHide={props.toggleSidebar}>
                <Offcanvas.Title id={`offcanvasNavbarLabel`}>
                    Chef by Chomp
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column p-0">
                <Nav className="flex-grow-1 pe-3">
                    {venueName && (
                        <div className={styles.venueName}>{venueName}</div>
                    )}
                    {profile && (
                        <SidebarButton
                            lable="Profile"
                            icon={<User />}
                            onClick={showProfile}
                        />
                    )}

                    <SidebarButton
                        lable="Settings"
                        icon={<Settings />}
                        onClick={showSettings}
                    />

                    {allowInviteUser && (
                        <SidebarButton
                            lable="Invite to Chomp"
                            icon={<UserPlus />}
                            onClick={showInviteUser}
                        />
                    )}

                    <SidebarButton
                        lable={profile ? "Log Out" : "Sign out"}
                        icon={<Signout />}
                        onClick={confirmSignout}
                    />

                    <SidebarButton
                        lable={`${recordsWaitingMessage}`}
                        icon={<Clock />}
                        onClick={showDiaryQueue}
                    />
                </Nav>
                <Nav>
                    <DeviceId label={appVersion} />

                    <DeviceId label={deviceIdentifier} />

                    <NetworkStatus
                        offlineStart={offlineStart}
                        queuedDiaryEntries={queuedDiaryEntries}
                    />
                </Nav>
            </Offcanvas.Body>
        </>
    );
}
