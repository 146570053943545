import React from "react";
import classnames from "classnames";
import { ComponentProps } from "./types";
import formStyles from "./Form.module.css";
import styles from "./TimeEntry.module.css";
import { Clock } from "icons/icons";
import "react-datepicker/dist/react-datepicker.css";

interface Props extends ComponentProps {}

function getTime(date: Date): string {
    const minutes = `${date.getMinutes()}`.padStart(2, "0");
    return `${date.getHours()}:${minutes}`;
}

export default function TimeEntry(props: Props) {
    let error;
    if (
        props.error ||
        (props.problem && props.problemError) ||
        (props.invalid && props.invalidError)
    ) {
        let errorMessage;
        if (props.problem && props.problemError) {
            errorMessage = props.problemError;
            error = <p className={formStyles.problem}>{errorMessage}</p>;
        } else if (props.invalid && props.invalidError) {
            errorMessage = props.invalidError;
            error = <p className={formStyles.invalid}>{errorMessage}</p>;
        } else {
            errorMessage = props.error?.message;
            error = <p className={formStyles.error}>{errorMessage}</p>;
        }
    }

    const onChange = (time: String, autoChange = false) => {
        props.onChange(props.model, time, void 0, autoChange);
    };

    let time = getTime(new Date());
    if (props.value) {
        time = props.value;
    } else {
        onChange(time, true);
    }

    let classes = [styles.TimeEntry];
    if (props.problem) {
        classes.push(styles.problem);
    }

    return (
        <div className={classnames(...classes)}>
            <p
                dangerouslySetInnerHTML={{ __html: props.label || "" }}
                className={formStyles.label}
            ></p>
            <input
                className={styles.input}
                type="time"
                value={time}
                onChange={(e) => onChange(e.target.value)}
                onClick={(e) => (e.target as HTMLInputElement).showPicker()}
            />
            <Clock className={styles.clock} />
            <div></div>

            {error}
        </div>
    );
}
