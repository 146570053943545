import React, { useCallback } from "react";
import classnames from "classnames";
import { Popover, ArrowContainer, PopoverPosition } from "react-tiny-popover";
import type { TaskData } from "./types";
import { groupData, TaskObjectsFunction } from "./functions";
import styles from "./TaskGroupPopover.module.scss";
import useTaskGroupPopover from "./useTaskGroupPopover";
import Modal from "app/Modal";
import { Xmark } from "icons/icons";
import iconStyles from "icons/Icon.module.css";
import { sortTasks } from "./functions";
import TaskInfo from "./TaskInfo";
import { CategoryView } from "features/environment/types";
import type { SavedDiaryEntry } from "app/types";
import { PenToSquare } from "icons/icons";

interface Props {
    group: TaskData[];
    categoryView?: CategoryView;
    getTaskObjects: TaskObjectsFunction;
    children: React.ReactElement;
    showLastPerformed?: boolean;
    savedDiaryEntries: Record<number, SavedDiaryEntry>;
}

const mobileMaxCss =
    window.getComputedStyle(document.body).getPropertyValue("--mobile-max") ||
    "768px";
const mobileMax = parseInt(mobileMaxCss, 10);

export default function TaskGroupPopover(props: Props) {
    const category = groupData(props.group, props.getTaskObjects);
    const {
        recentDiaries,
        showPopoverInformation,
        selectedTask,
        getDiaryEntryHasErrors,
        clickedOutside,
    } = useTaskGroupPopover(props.categoryView);

    const onSelectTask = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            const taskId = Number(e.currentTarget.dataset.taskId);
            const tasks = props.group.filter((task) => task.id === taskId);
            if (tasks.length > 0) {
                selectedTask(tasks[0]);
            }
        },
        [props, selectedTask]
    );
    const popoverId = `category_${category?.id}_popover`;
    const popoverList: React.ReactElement[] = [];
    const sortedGroup = sortTasks(
        props.group,
        "template_name",
        "overdueTime",
        "name"
    );
    const now = new Date().getTime() / 1000;
    let lastTaskTemplateName = "";
    let displayTaskTemplate = false;
    for (let task of sortedGroup) {
        let status: string | undefined = void 0;
        if (task.overdueTime) {
            status = task.overdueTime < now ? "overdue" : "due";
        }
        // only show the template name if it is different than the previous task
        if (task.template_name !== lastTaskTemplateName) {
            lastTaskTemplateName = task.template_name;
            displayTaskTemplate = true;
        } else {
            displayTaskTemplate = false;
        }
        let title = (
            <>
                <span>{task.name}</span>
                <TaskInfo
                    task={task}
                    status={status}
                    showPopoverInformation={showPopoverInformation}
                    recentDiaries={recentDiaries}
                    getDiaryEntryHasErrors={getDiaryEntryHasErrors}
                />
            </>
        );

        // show template name first
        if (displayTaskTemplate) {
            popoverList.push(
                <li
                    className={styles.templateName}
                    key={`${popoverId}_${task.id}_title`}
                >
                    {task.template_name}
                </li>
            );
        }

        // show task
        let classNames = [styles[status || ""]];
        if (props.savedDiaryEntries[task.id]) {
            classNames.push(styles.inProgress);
        }
        popoverList.push(
            <li
                key={`${popoverId}_${task.id}`}
                data-task-id={task.id}
                onClick={onSelectTask}
                className={classnames(...classNames)}
            >
                {props.savedDiaryEntries[task.id] && (
                    <PenToSquare className={iconStyles.verySmallIcon} />
                )}
                {title}
            </li>
        );
    }

    let popover;
    let positions: PopoverPosition[] = ["right", "left", "top", "bottom"];
    const isMobile = window.innerWidth < mobileMax;
    if (isMobile) {
        popover = (
            <>
                {props.children}
                <Modal
                    isOpaque={false}
                    dismiss={clickedOutside}
                    contentClass={styles.popoverModal}
                >
                    <div className={styles.popoverGroup}>
                        <div className={styles.closeContainer}>
                            <h3>{category?.name}</h3>
                            <div className={styles.close}>
                                <Xmark
                                    className={iconStyles.smallIcon}
                                    onClick={clickedOutside}
                                />
                            </div>
                        </div>
                        <ul>{popoverList}</ul>
                    </div>
                </Modal>
            </>
        );
    } else {
        popover = (
            <Popover
                isOpen={true}
                positions={positions}
                content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor={"#fff"}
                        arrowSize={10}
                        className={styles.popoverArrowContainer}
                        arrowClassName={styles.popoverArrow}
                    >
                        <div className={styles.popoverGroup}>
                            <ul>{popoverList}</ul>
                        </div>
                    </ArrowContainer>
                )}
            >
                {props.children}
            </Popover>
        );
    }

    return popover;
}
