import { useAppSelector, useAppDispatch } from "app/hooks";
import {
    pushActiveScreen,
    selectActiveScreen,
    selectActiveTasksScreen,
    setActiveTasksScreen,
    setShowSidebar,
    setShowSignoutConfirmation,
    selectDiaryEntryQueue,
    selectDiaryFilesQueue,
    setShowDiaryQueue,
    setShowInviteUser,
    setUrlPath,
    selectActiveVenueId,
} from "app/appSlice";
import { selectVenueData } from "features/venue/venueSlice";
import {
    selectOfflineStart,
    selectShowEmailLogin,
} from "features/environment/envSlice";
import { selectTeam } from "features/staff/staffSlice";
import type { TasksScreen } from "app/types";
import type { screen } from "app/types";
import { getStore } from "app/store";
import type { DiaryEntry, DiaryFile } from "features/tasks/types";
import { useCallback } from "react";
import { getUniqueId, getBuildVersion } from "app/util";
import { selectProfile } from "features/loginToken/loginTokenSlice";
import type { Profile } from "features/loginToken/types";
import { showRoute } from "app/routeListeners";
import type { RouteName } from "app/routes";
import { isULID } from "app/util";

export interface SidebarData {
    activeScreen: screen;
    activeTasksScreen: TasksScreen;
    offlineStart?: Date;
    queuedDiaryEntries: DiaryEntry[];
    queuedDiaryFiles: DiaryFile[];
    venueName?: string;
    deviceId: string;
    buildVersion: string;
    profile?: Profile;
    allowInviteUser: boolean;
    showSettings: () => void;
    confirmSignout: () => void;
    showInviteUser: () => void;
    resetTaskScreen: () => void;
    showDiaryQueue: () => void;
    showProfile: () => void;
}

const selectRoute = (route: RouteName) => {
    getStore().dispatch(showRoute(route));
    getStore().dispatch(setShowSidebar(false));
};

const showInviteUser = () => {
    getStore().dispatch(setShowInviteUser(true));
    getStore().dispatch(setShowSidebar(false));
};

const resetTaskScreen = () => {
    getStore().dispatch(setActiveTasksScreen({}));
};

const showSettings = () => {
    selectRoute("settings");
};

const showProfile = () => {
    selectRoute("profile");
};

export default function useSidebar(): SidebarData {
    const activeScreen = useAppSelector(selectActiveScreen);
    const activeTasksScreen = useAppSelector(selectActiveTasksScreen);
    const offlineStart = useAppSelector(selectOfflineStart);
    const queuedDiaryEntries = useAppSelector(selectDiaryEntryQueue);
    const queuedDiaryFiles = useAppSelector(selectDiaryFilesQueue);
    const venueId = useAppSelector(selectActiveVenueId);
    const venueData = useAppSelector((state) =>
        selectVenueData(state, venueId)
    );
    const deviceId = getUniqueId();
    const buildVersion = getBuildVersion();
    const profile = useAppSelector(selectProfile);

    let allowInviteUser = false;
    // Allow invite if team has migrated users
    const team = useAppSelector((state) => selectTeam(state, venueId));
    if (!allowInviteUser && team?.length > 0) {
        if (isULID(team[0].id)) allowInviteUser = true;
    }

    const dispatch = useAppDispatch();

    let venueName;
    if (venueData) {
        venueName = venueData.venueName;
    }

    const confirmSignout = useCallback(() => {
        dispatch(setShowSignoutConfirmation(true));
    }, [dispatch]);

    const showDiaryQueue = useCallback(() => {
        dispatch(setShowDiaryQueue(true));
        dispatch(setShowSidebar(false));
    }, [dispatch]);

    return {
        activeScreen,
        activeTasksScreen,
        offlineStart,
        queuedDiaryEntries,
        queuedDiaryFiles,
        venueName,
        deviceId,
        buildVersion,
        profile,
        allowInviteUser,
        confirmSignout,
        showSettings,
        showInviteUser,
        resetTaskScreen,
        showDiaryQueue,
        showProfile,
    };
}
