import {
    createSlice,
    PayloadAction,
    prepareAutoBatched,
} from "@reduxjs/toolkit";
import type { ISO8601Datetime, ULID } from "types";
import { RootState } from "app/store";
import { DiaryEntry, DiaryFile, Segment } from "features/tasks/types";
import type {
    screen,
    TasksScreen,
    ActiveFormData,
    SavedDiaryEntry,
} from "./types";
import type {
    NetworkOperation,
    NetworkOperationType,
} from "features/networkQueue/types";
import type {
    ActionCreatorWithOptionalPayload,
    ActionCreatorWithPayload,
} from "@reduxjs/toolkit";
import type { AppToast } from "app/types";
import { VenuePayloadAction } from "./types";

export type ServerEndpoint =
    | "tasks"
    | "suppliers"
    | "receivers"
    | "staff"
    | "team"
    | "venue"
    | "invite"
    | "profile"
    | "password"
    | "refeshToken"
    | "taskNames"
    | "segments";

export interface ServerError {
    httpCode: number;
    data: any;
    datetime: ISO8601Datetime;
}

export type NetworkQueue = Partial<
    Record<NetworkOperationType, NetworkOperation[]>
>;

export interface AppState {
    activeVenueId?: number;
    activeUser?: number | ULID;
    activeTasksScreen: TasksScreen;
    activeTaskForm?: ActiveFormData;
    pendingDiaryEntries?: DiaryEntry[];
    pendingDiaryFiles?: DiaryFile[];
    diaryEntryQueue: DiaryEntry[];
    diaryFileQueue: DiaryFile[];
    savedDiaryEntries: Record<number, SavedDiaryEntry>;
    showUserSelect: boolean;
    showSidebar: boolean;
    showSignoutConfirmation: boolean;
    showResetConfirmation: boolean;
    showDiaryQueue: boolean;
    hasBeenIdle: boolean;
    screenStack: screen[];
    appToasts?: AppToast[];
    reloadingPlan: boolean;
    reloadingTasks: boolean;
    reloadingSuppliers: boolean;
    reloadingStaff: boolean;
    submittingDebugReport: boolean;
    serverErrors: Record<ServerEndpoint, ServerError>;
    urlPath: string;
    urlParams: Record<string, string>;
    error?: string;
    showInviteUser: boolean;
    networkQueue: NetworkQueue;
    showVenueSelect: boolean;
    activeSegment: Record<number, Segment | undefined>;
    showSegmentSelect: boolean;
}

export const DEFAULT_SCREEN = "tasks";

const initialState: AppState = {
    activeTasksScreen: {},
    diaryEntryQueue: [],
    diaryFileQueue: [],
    savedDiaryEntries: {},
    showUserSelect: false,
    showSidebar: false,
    showSignoutConfirmation: false,
    showResetConfirmation: false,
    showDiaryQueue: false,
    showInviteUser: false,
    hasBeenIdle: false,
    screenStack: ["init"],
    reloadingPlan: false,
    reloadingTasks: false,
    reloadingSuppliers: false,
    reloadingStaff: false,
    submittingDebugReport: false,
    serverErrors: {} as Record<ServerEndpoint, ServerError>,
    urlPath: "",
    urlParams: {},
    networkQueue: {},
    showVenueSelect: false,
    activeSegment: {},
    showSegmentSelect: false,
};

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setActiveVenueId: (
            state: AppState,
            action: PayloadAction<number | undefined>
        ) => {
            state.activeVenueId = action.payload;
        },
        setActiveScreen: (state: AppState, action: PayloadAction<screen>) => {
            state.screenStack = [action.payload];
        },
        pushActiveScreen: (state: AppState, action: PayloadAction<screen>) => {
            if (
                state.screenStack[state.screenStack.length - 1] ===
                action.payload
            )
                return;
            state.screenStack.push(action.payload);
        },
        popActiveScreen: (state: AppState) => {
            state.screenStack.pop();
        },
        setActiveUser: (
            state: AppState,
            action: PayloadAction<number | ULID | undefined>
        ) => {
            state.activeUser = action.payload;
        },
        setActiveTasksScreen: (
            state: AppState,
            action: PayloadAction<TasksScreen>
        ) => {
            state.activeTasksScreen = action.payload;
        },
        setActiveTaskForm: (
            state: AppState,
            action: PayloadAction<ActiveFormData | undefined>
        ) => {
            state.activeTaskForm = action.payload;
        },
        addDiaryEntriesToQueue: (
            state: AppState,
            action: PayloadAction<DiaryEntry[]>
        ) => {
            state.diaryEntryQueue = state.diaryEntryQueue.concat(
                action.payload
            );
        },
        removeDiaryEntryFromQueue: (
            state: AppState,
            action: PayloadAction<DiaryEntry>
        ) => {
            state.diaryEntryQueue = state.diaryEntryQueue.filter(
                (diary) => diary.uuid !== action.payload.uuid
            );
        },
        removeDiaryEntriesFromQueue: (
            state: AppState,
            action: PayloadAction<DiaryEntry[]>
        ) => {
            const uuids = action.payload.map((diary) => diary.uuid);
            state.diaryEntryQueue = state.diaryEntryQueue.filter(
                (diary) => !uuids.includes(diary.uuid)
            );
        },
        setPendingDiaryEntry: (
            state: AppState,
            action: PayloadAction<DiaryEntry | undefined>
        ) => {
            let diaryEntries;
            if (action.payload) diaryEntries = [action.payload];
            state.pendingDiaryEntries = diaryEntries;
        },
        setPendingDiaryEntries: (
            state: AppState,
            action: PayloadAction<DiaryEntry[] | undefined>
        ) => {
            state.pendingDiaryEntries = action.payload;
        },
        addDiaryFilesToQueue: (
            state: AppState,
            action: PayloadAction<DiaryFile[]>
        ) => {
            state.diaryFileQueue = state.diaryFileQueue.concat(action.payload);
        },
        removeDiaryFileFromQueue: (
            state: AppState,
            action: PayloadAction<DiaryFile>
        ) => {
            state.diaryFileQueue = state.diaryFileQueue.filter(
                (file) => file.filename !== action.payload.filename
            );
        },
        removeDiaryFilesFromQueue: (
            state: AppState,
            action: PayloadAction<DiaryFile[]>
        ) => {
            const uuids = action.payload.map((file) => file.diary_entry);
            state.diaryFileQueue = state.diaryFileQueue.filter(
                (file) => !uuids.includes(file.diary_entry)
            );
        },
        setPendingDiaryFile: (
            state: AppState,
            action: PayloadAction<DiaryFile | undefined>
        ) => {
            let diaryFiles;
            if (action.payload) diaryFiles = [action.payload];
            state.pendingDiaryFiles = diaryFiles;
        },
        setPendingDiaryFiles: (
            state: AppState,
            action: PayloadAction<DiaryFile[] | undefined>
        ) => {
            state.pendingDiaryFiles = action.payload;
        },
        setShowUserSelect: (
            state: AppState,
            action: PayloadAction<boolean>
        ) => {
            state.showUserSelect = action.payload;
        },
        setShowSidebar: (state: AppState, action: PayloadAction<boolean>) => {
            state.showSidebar = action.payload;
        },
        setShowSignoutConfirmation: (
            state: AppState,
            action: PayloadAction<boolean>
        ) => {
            state.showSignoutConfirmation = action.payload;
        },
        setShowResetConfirmation: (
            state: AppState,
            action: PayloadAction<boolean>
        ) => {
            state.showResetConfirmation = action.payload;
        },
        setShowDiaryQueue: (
            state: AppState,
            action: PayloadAction<boolean>
        ) => {
            state.showDiaryQueue = action.payload;
        },
        setShowInviteUser: (
            state: AppState,
            action: PayloadAction<boolean>
        ) => {
            state.showInviteUser = action.payload;
        },
        setHasBeenIdle: (state: AppState, action: PayloadAction<boolean>) => {
            state.hasBeenIdle = action.payload;
        },
        setReloadingPlan: (state: AppState, action: PayloadAction<boolean>) => {
            state.reloadingPlan = action.payload;
        },
        setReloadingTasks: (
            state: AppState,
            action: PayloadAction<boolean>
        ) => {
            state.reloadingTasks = action.payload;
        },
        setReloadingSuppliers: (
            state: AppState,
            action: PayloadAction<boolean>
        ) => {
            state.reloadingSuppliers = action.payload;
        },
        setDebugReport: (state: AppState, action: PayloadAction<boolean>) => {
            state.submittingDebugReport = action.payload;
        },
        setReloadingStaff: (
            state: AppState,
            action: PayloadAction<boolean>
        ) => {
            state.reloadingStaff = action.payload;
        },
        resetState: (state: AppState, action: PayloadAction): AppState => {
            return {
                ...initialState,
                diaryEntryQueue: state.diaryEntryQueue,
                diaryFileQueue: state.diaryFileQueue,
                screenStack: ["login"],
                urlPath: state.urlPath,
                urlParams: state.urlParams,
                networkQueue: state.networkQueue,
                serverErrors: state.serverErrors,
            };
        },
        addAppToast: (state: AppState, action: PayloadAction<AppToast>) => {
            if (state.appToasts) {
                state.appToasts.push(action.payload);
            } else {
                state.appToasts = [action.payload];
            }
        },
        removeAppToast: (state: AppState, action: PayloadAction<AppToast>) => {
            if (state.appToasts) {
                const removedToast = action.payload;
                state.appToasts = state.appToasts.filter(
                    (toast) => toast.id !== removedToast.id
                );
            }
        },
        setUrlPath: (
            state: AppState,
            action: PayloadAction<{
                url: string;
                processed?: boolean;
                pushState?: boolean;
            }>
        ) => {
            state.urlPath = action.payload.url;
        },
        setUrlParams: (
            state: AppState,
            action: PayloadAction<Record<string, string>>
        ) => {
            state.urlParams = action.payload;
        },
        setServerError: (
            state: AppState,
            action: PayloadAction<
                [endpoint: ServerEndpoint, error: ServerError | undefined]
            >
        ) => {
            const [endpoint, error] = action.payload;
            if (error) {
                state.serverErrors[endpoint] = error;
            } else {
                let serverErrors = {
                    ...state.serverErrors,
                };
                delete serverErrors[endpoint];

                state.serverErrors = serverErrors;
            }
        },
        setServerErrors: (
            state: AppState,
            action: PayloadAction<Record<ServerEndpoint, ServerError> | {}>
        ) => {
            state.serverErrors = action.payload as Record<
                ServerEndpoint,
                ServerError
            >;
        },
        setError: (
            state: AppState,
            action: PayloadAction<string | undefined>
        ) => {
            state.error = action.payload;
        },
        queueNetworkOperation: {
            reducer(state: AppState, action: PayloadAction<NetworkOperation>) {
                const queueType = action.payload.type;
                if (!state.networkQueue[queueType]) {
                    state.networkQueue[queueType] = [];
                }
                let queue = state.networkQueue[queueType];
                if (!queue) return;
                const operation = action.payload as NetworkOperation<any>;
                for (let i = 0; i < queue.length; i++) {
                    const queuedOperation = queue[i];
                    if (queuedOperation.id === operation.id) {
                        queue[i] = operation;
                        return;
                    }
                }

                queue.push(operation);
            },
            prepare: prepareAutoBatched<NetworkOperation>(),
        },
        // Use this when you only want a single operation in the queue at a time
        setNetworkOperation: (
            state: AppState,
            action: PayloadAction<NetworkOperation>
        ) => {
            const queueType = action.payload.type;
            if (!state.networkQueue[queueType]) {
                state.networkQueue[queueType] = [];
            }
            const operation = action.payload as NetworkOperation<any>;
            state.networkQueue[queueType] = [operation];
        },
        removeNetworkOperation: (
            state: AppState,
            action: PayloadAction<NetworkOperation>
        ) => {
            const queueType = action.payload.type;
            if (!state.networkQueue[queueType]) return;
            let queue = state.networkQueue[queueType];
            if (!queue) return;
            // can't use filter due to type mismatch issues
            for (let i = 0; i < queue.length; i++) {
                const operation = queue[i];
                if (operation.id === action.payload.id) {
                    queue.splice(i, 1);
                }
            }
        },
        setShowVenueSelect: (
            state: AppState,
            action: PayloadAction<boolean>
        ) => {
            state.showVenueSelect = action.payload;
        },
        setActiveSegment: (
            state: AppState,
            action: VenuePayloadAction<Segment | undefined>
        ) => {
            state.activeSegment = {
                ...state.activeSegment,
                [action.payload.venueId]: action.payload.data,
            };
        },
        setShowSegmentSelect: (
            state: AppState,
            action: PayloadAction<boolean>
        ) => {
            state.showSegmentSelect = action.payload;
        },
        addSavedDiaryEntry: (
            state: AppState,
            action: PayloadAction<SavedDiaryEntry>
        ) => {
            const taskId = action.payload.taskId;
            state.savedDiaryEntries[taskId] = action.payload;
        },
        removeSavedDiaryEntry: (
            state: AppState,
            action: PayloadAction<number>
        ) => {
            const taskId = action.payload;
            if (state.savedDiaryEntries[taskId]) {
                delete state.savedDiaryEntries[taskId];
            }
        },
        removeOutdatedSavedDiaries: (state: AppState) => {
            const now = new Date().getTime() / 1000;
            for (let taskId in state.savedDiaryEntries) {
                const savedDiary = state.savedDiaryEntries[taskId];
                if (savedDiary.nextDue && savedDiary.nextDue < now) {
                    delete state.savedDiaryEntries[taskId];
                }
            }
        },
    },
});

export const {
    setActiveVenueId,
    setActiveScreen,
    pushActiveScreen,
    popActiveScreen,
    setActiveUser,
    setActiveTasksScreen,
    setActiveTaskForm,
    addDiaryEntriesToQueue,
    removeDiaryEntryFromQueue,
    removeDiaryEntriesFromQueue,
    setPendingDiaryEntry,
    setPendingDiaryEntries,
    addDiaryFilesToQueue,
    removeDiaryFileFromQueue,
    removeDiaryFilesFromQueue,
    setPendingDiaryFile,
    setPendingDiaryFiles,
    setShowUserSelect,
    setShowSidebar,
    setShowSignoutConfirmation,
    setShowResetConfirmation,
    setShowDiaryQueue,
    setShowInviteUser,
    setHasBeenIdle,
    setReloadingPlan,
    setReloadingTasks,
    setReloadingSuppliers,
    setDebugReport,
    setReloadingStaff,
    resetState,
    addAppToast,
    removeAppToast,
    setUrlPath,
    setUrlParams,
    setServerError,
    setServerErrors,
    setError,
    queueNetworkOperation,
    setNetworkOperation,
    removeNetworkOperation,
    setShowVenueSelect,
    setActiveSegment,
    setShowSegmentSelect,
    addSavedDiaryEntry,
    removeSavedDiaryEntry,
    removeOutdatedSavedDiaries,
} = appSlice.actions;

export const selectActiveVenueId = (state: RootState): number | undefined =>
    state.app.activeVenueId;
export const selectActiveScreen = (state: RootState): screen => {
    const screenStackLength = state.app.screenStack.length;
    return screenStackLength > 0
        ? state.app.screenStack[screenStackLength - 1]
        : DEFAULT_SCREEN;
};
export const selectPreviousScreen = (state: RootState): screen | undefined => {
    const screenStackLength = state.app.screenStack.length;
    let screen;
    if (screenStackLength > 1)
        screen = state.app.screenStack[screenStackLength - 2];
    return screen;
};

export const selectActiveUser = (state: RootState): number | ULID | undefined =>
    state.app.activeUser;
export const selectActiveTasksScreen = (state: RootState): TasksScreen =>
    state.app.activeTasksScreen;
export const selectActiveTaskForm = (
    state: RootState
): ActiveFormData | undefined => state.app.activeTaskForm;
export const selectDiaryEntryQueue = (state: RootState): DiaryEntry[] =>
    state.app.diaryEntryQueue;
export const selectPendingDiaries = (
    state: RootState
): DiaryEntry[] | undefined => state.app.pendingDiaryEntries;
export const selectDiaryFilesQueue = (state: RootState): DiaryFile[] =>
    state.app.diaryFileQueue;
export const selectPendingDiaryFiles = (
    state: RootState
): DiaryFile[] | undefined => state.app.pendingDiaryFiles;
export const selectShowUserSelect = (state: RootState): boolean =>
    state.app.showUserSelect;
export const selectShowSidebar = (state: RootState): boolean =>
    state.app.showSidebar;
export const selectShowSignoutConfirmation = (state: RootState): boolean =>
    state.app.showSignoutConfirmation;
export const selectShowResetConfirmation = (state: RootState): boolean =>
    state.app.showResetConfirmation;
export const selectShowDiaryQueue = (state: RootState): boolean =>
    state.app.showDiaryQueue;
export const selectShowInviteUser = (state: RootState): boolean =>
    state.app.showInviteUser;
export const selectHasBeenIdle = (state: RootState): boolean =>
    state.app.hasBeenIdle;
export const selectReloadingPlan = (state: RootState): boolean =>
    state.app.reloadingPlan;
export const selectReloadingTasks = (state: RootState): boolean =>
    state.app.reloadingTasks;
export const selectReloadingSuppliers = (state: RootState): boolean =>
    state.app.reloadingSuppliers;
export const selectDebugReport = (state: RootState): boolean =>
    state.app.submittingDebugReport;
export const selectReloadingStaff = (state: RootState): boolean =>
    state.app.reloadingStaff;
export const selectAppToasts = (state: RootState): AppToast[] | undefined =>
    state.app.appToasts;
export const selectUrlPath = (state: RootState): string => state.app.urlPath;
export const selectUrlsParams = (state: RootState): Record<string, string> =>
    state.app.urlParams;
export const selectServerErrors = (
    state: RootState
): Record<ServerEndpoint, ServerError> | undefined => state.app.serverErrors;
export const selectError = (state: RootState) => state.app.error;
export const getActiveFormDataSetter = (
    state: RootState
):
    | ActionCreatorWithOptionalPayload<ActiveFormData | undefined, string>
    | undefined => {
    if (selectActiveScreen(state) === "tasks") {
        return setActiveTaskForm;
    }

    return void 0;
};
export const getActiveScreenSetter = (
    state: RootState
): ActionCreatorWithPayload<TasksScreen, string> | undefined => {
    if (selectActiveScreen(state) === "tasks") {
        return setActiveTasksScreen;
    }

    return void 0;
};
export const selectNetworkQueue = (state: RootState): NetworkQueue =>
    state.app.networkQueue;
export const selectShowVenueSelect = (state: RootState): boolean =>
    state.app.showVenueSelect;
export const selectActiveSegment = (state: RootState, venueId: number) =>
    state.app.activeSegment ? state.app.activeSegment[venueId] : void 0;
export const selectShowSegmentSelect = (state: RootState): boolean =>
    state.app.showSegmentSelect;
export const selectSavedDiaryEntries = (
    state: RootState
): Record<number, SavedDiaryEntry> => {
    return state.app.savedDiaryEntries;
};
export const selectSavedDiaryEntry = (
    state: RootState,
    taskId: number
): SavedDiaryEntry => {
    return state.app.savedDiaryEntries[taskId];
};
export default appSlice.reducer;
