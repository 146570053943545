import { ReactNode } from "react";
import styles from "./Confirmation.module.scss";
import { Xmark } from "icons/icons";
import iconStyles from "icons/Icon.module.css";
import Button from "features/tasks/form/Button";

interface Props {
    onCancel: () => void;
    onConfirm: () => void;
    onAlternateConfirm?: () => void;
    title: string;
    children?: ReactNode;
    confirmTitle: string;
    alternateTitle?: string;
}

export default function Confirmation(props: Props) {
    return (
        <div className={styles.container}>
            <div className={styles.Confirmation}>
                <h2 className={styles.heading}>
                    {props.title}
                    <div style={{ cursor: "pointer" }} onClick={props.onCancel}>
                        <Xmark className={iconStyles.smallIcon} />
                    </div>
                </h2>
                {props.children && (
                    <div className={styles.content}>{props.children}</div>
                )}
                <footer>
                    {props.onAlternateConfirm && props.alternateTitle && (
                        <Button
                            onClick={props.onAlternateConfirm}
                            className={styles.alternate}
                        >
                            {props.alternateTitle}
                        </Button>
                    )}
                    {!props.onAlternateConfirm && (
                        <Button
                            onClick={props.onCancel}
                            className={styles.cancel}
                        >
                            Cancel
                        </Button>
                    )}
                    <Button onClick={props.onConfirm} type="submit">
                        {props.confirmTitle}
                    </Button>
                </footer>
            </div>
        </div>
    );
}
