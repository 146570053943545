import styles from "./ProfileScreen.module.scss";
import useProfileScreen from "./useProfileScreen";
import ProfileView from "./ProfileView";
import ProfileForm from "./ProfileForm";

export default function ProfileScreen() {
    const { profile, isEditing, toggleEdit, showChangePassword, logout } =
        useProfileScreen();
    if (!profile) return <div>No Profile</div>;

    let component;
    if (isEditing) {
        component = <ProfileForm onDone={toggleEdit} />;
    } else {
        component = (
            <ProfileView
                profile={profile}
                onEdit={toggleEdit}
                onChangePassword={showChangePassword}
                onLogout={logout}
            />
        );
    }

    return <div className={styles.ProfileScreen}>{component}</div>;
}
